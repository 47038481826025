import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title, bodyClasses, bodyId }) {

  const data = useStaticQuery(graphql`
  query SEOQuery {
    site: sanitySiteSettings {
      title
      description
      keywords
    }
  }
`);

  const metaDescription = description || (data.site && data.site.description) || '';
  const siteTitle = (data.site && data.site.title) || '';
  let bodyAttributes = {};
  bodyClasses ? bodyAttributes.class = bodyClasses : null;
  bodyId ? bodyAttributes.id = bodyId : null;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
      bodyAttributes={bodyAttributes}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: 'Kleiner Perkins Fellows'
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: 'http://kp-media.s3.us-west-1.amazonaws.com/gtmg/logo-white-black-ln-w.png'
        },
        {
          property: 'og:url',
          content: 'https://fellows.kleinerperkins.com'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: 'Kleiner Perkins Fellows'
        },
        {
          name: 'twitter:title',
          content: 'Kleiner Perkins Fellows'
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:image',
          content: 'http://kp-media.s3.us-west-1.amazonaws.com/gtmg/logo_white.png'
        }
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', ')
            }
            : []
        )
        .concat(meta)}
    />

  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
