import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { getYear } from 'date-fns';
import _ from 'lodash';
import FacebookIcon from '../images/icons/facebook-icon.svg';
import LinkedinIcon from '../images/icons/linkedin-icon.svg';
import TwitterIcon from '../images/icons/twitter-icon.svg';
import TiktokIcon from '../images/icons/tiktok-icon.svg';

const Footer = () => {

    const data = useStaticQuery(graphql`
    query FooterQuery {
      allSanityMenus {
        edges {
          node {
            title
            secondaryMenu {
              title
              link
              external
            }
          }
        }
      }
      site: sanitySiteSettings {
        disclaimer
        twitter
        linkedin
        facebook
        tiktok
      }
  }
  `);

    const facebook = _.get(data, 'site.facebook', '');
    const linkedin = _.get(data, 'site.linkedin', '');
    const twitter = _.get(data, 'site.twitter', '');
    const tiktok = _.get(data, 'site.tiktok', '');
    const secondaryMenu = _.get(data, 'allSanityMenus.edges[0].node.secondaryMenu', []);

    return (

        <div className="footer">
            <div className="container">
                <div className="row">
                    <section className="col-sm-12 d-flex justify-content-center">
                        <div className="social">
                            {facebook && (
                                <a href={facebook} target="_blank" rel="noopener noreferrer">
                                    <FacebookIcon />
                                </a>
                            )}
                            {linkedin && (
                                <a href={linkedin} target="_blank" rel="noopener noreferrer">
                                    <LinkedinIcon />
                                </a>
                            )}
                            {twitter && (
                                <a href={twitter} className="twitter" target="_blank" rel="noopener noreferrer">
                                    <TwitterIcon />
                                </a>
                            )}
                            {tiktok && (
                                <a href={tiktok} target="_blank" rel="noopener noreferrer">
                                    <TiktokIcon />
                                </a>
                            )}
                        </div>
                    </section>
                    <div className="col-sm-12 d-flex justify-content-center">
                        <ul className="nav">
                            {secondaryMenu.map(menu => (
                                <li className="nav-item" key={menu.title}>
                                    {menu.external ? (
                                        <a className="nav-link" href={menu.link} key={menu.title} target="_blank" rel="noopener">{menu.title}</a>
                                    ) : (
                                        <Link className="nav-link" to={menu.link} key={menu.title}>{menu.title}</Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="copyright">
              &copy; {getYear(new Date)} Kleiner Perkins Fellows
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Footer;