import React from 'react';
import Header from '../components/header/header';
import Footer from '../components/footer';
import '../styles/mn_app.scss';

const Layout = ({ children, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} />
    <div className="layout">{children}</div>
    <Footer />
  </>
);

export default Layout;
