import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { getYear } from 'date-fns';
import _ from 'lodash';
import IconXCircle from '../icon/icon-x-circle';
import Lockup from './lockup';
import Hamburger from './hamburger';
import ReactMarkdown from 'react-markdown/with-html';


const Header = () => {

  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
    setSearch(false);
    !collapsed ? document.body.classList.add('js-nav-active') :
      document.body.classList.remove('js-nav-active');
  };

  const [searchToggled, setSearch] = useState(false);
  const toggleSearch = () => {
    setSearch(!searchToggled);
  };

  const data = useStaticQuery(graphql`
    query MenuQuery {
      allSanityMenus {
        edges {
          node {
            title
            mainMenu {
              title
              link
              external
            }
            secondaryMenu {
              title
              link
              external
            }
          }
        }
      }
      site: sanitySiteSettings {
        disclaimer
      }
    }
  `);

  const primaryMenu = _.get(data, 'allSanityMenus.edges[0].node.mainMenu', []);
  const secondaryMenu = _.get(data, 'allSanityMenus.edges[0].node.secondaryMenu', []);
  const disclaimer = _.get(data, 'site.disclaimer', '');

  return (
    <Fragment>
      <header id="header" className="navbar-dark fixed-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Lockup />
              <Hamburger collapsed={collapsed} handleToggle={toggle} />
            </div>

            <div className={`navbar-collapse ${collapsed ? 'collapse show' : 'collapse'}`} id="main-nav">

              <div className="main-nav-scroll">
                <div className="main-nav-inner">

                  <div className="main-nav-center">
                    <a href="#" className="navbar-x" data-toggle="collapse" data-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                      <IconXCircle collapsed={collapsed} handleToggle={toggle} />
                    </a>

                    <ul className="navbar-nav navbar-main">
                      {primaryMenu.map(menu => (
                        <li className="nav-item" key={menu.title}>
                          {menu.external ? (
                            <a href={menu.link} className="nav-link nav-link--primary" target="_blank" rel="noopener noreferrer" onClick={toggle}>{menu.title}</a>
                          ) : (
                              <Link to={menu.link} className="nav-link nav-link--primary" onClick={toggle}>{menu.title}</Link>
                            )}
                        </li>
                      ))}
                    </ul>

                    <hr />

                    <ul className="navbar-nav navbar-sub">
                      {secondaryMenu.map(menu => (
                        <li className="nav-item" key={menu.title}>
                          {menu.external ? (
                            <a href={menu.link} className="nav-link nav-link--secondary" target="_blank" rel="noopener" onClick={toggle}>{menu.title}</a>
                          ) : (
                              <Link to={menu.link} className="nav-link nav-link--secondary" onClick={toggle}>{menu.title}</Link>
                            )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="legal-disclaimer">
                    {disclaimer && <ReactMarkdown source={disclaimer} />}
                    <p>
                      &copy; {getYear(new Date)} Kleiner Perkins
                    </p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </header>
    </Fragment >
  );
};

export default Header;