import { graphql, StaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Layout from './layout';

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings {
      title
    }
  }
`;

function Wrapper(props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          );
        }
        return (
          <Layout
            {...props}
            siteTitle={data.site.title}
          />
        );
      }}
    />
  );
}

export default Wrapper;

